
const ASSETS_URL = 'https://d3vcyaq9ozusix.cloudfront.net'

const imageFormats = (PATH, image) => ({
    jpg: `${ASSETS_URL}/${PATH}/${image}.jpg`,
    jp2: `${ASSETS_URL}/${PATH}/${image}.jp2`,
    jxr: `${ASSETS_URL}/${PATH}/${image}.jxr`,
    webp: `${ASSETS_URL}/${PATH}/${image}.webp`
})

const videoFormats = (PATH, video) => ({
    mp4: `${ASSETS_URL}/${PATH}/${video}.mp4`,
    webm: `${ASSETS_URL}/${PATH}/${video}.webm`
})

export { ASSETS_URL, imageFormats, videoFormats } 