
const url = 'https://d3vcyaq9ozusix.cloudfront.net'

const formats = (type, index) => ({
    jpg: `${url}/screenshots/iPad/${type}/${index}/${index}.jpg`,
    jp2: `${url}/screenshots/iPad/${type}/${index}/${index}.jp2`,
    jxr: `${url}/screenshots/iPad/${type}/${index}/${index}.jxr`,
    webp: `${url}/screenshots/iPad/${type}/${index}/${index}.webp`
})

const industry = (type) => (
    Array(9).fill('').map((value, index) => { return formats(type, index+1) })
)

// const industry = (type) => ([
//     formats(type, 1),
//     formats(type, 2),
//     formats(type, 3),
//     formats(type, 4),
//     formats(type, 5),
//     formats(type, 6),
//     formats(type, 7),
//     formats(type, 8),
//     formats(type, 9),
// ])

const iPad = {
    bowling: industry('bowling'),
    carnival: industry('carnival'),
    salon: industry('salon'),
    restaurant: industry('restaurant'),
    retail: industry('retail'),
    realestate: industry('real estate'),
}

export { iPad }