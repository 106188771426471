import React from 'react';

// https://mariestarck.com/improve-your-react-app-performance-with-next-gen-images/
const ImagePicker = ({
  image,
  height,
  width,
  alt,
  priority
}) => {
  return (
    <picture>
       <source srcSet={image.webp} type="image/webp" />
       <source srcSet={image.jrx} type="image/jxr" />
       <source srcSet={image.jp2} type="image/jp2" />
       <source srcSet={image.jpg} type="image/jpeg" />
       <img src={image.jpg} height={height} width={width} alt={alt} fetchpriority={priority}/>
    </picture>
  );
};

// https://web.dev/efficient-animated-content/?utm_source=lighthouse&utm_medium=lr
const VideoPicker = ({
  video,
  width,
  className
}) => {
  console.log("VideoPicker", video)
  return (
    <video className={className} autoPlay loop muted playsInline width={width}>
       <source src={video.webm} type="video/webm" />
       <source src={video.mp4} type="video/mp4" />
    </video>
  );
};

export { ImagePicker, VideoPicker };