import React, { useState , useEffect} from "react";
import '../App.css'
import { ImagePicker } from '../components/optimize';
import { icon } from '../assets';


function Loading() {

    const [screenWidth, setScreenWidth] = useState(0)
    const [screenHeight, setScreenHeight] = useState(0)

    useEffect(() => { 

        updateDimensions();
    
        window.addEventListener('resize', updateDimensions);
        return () => 
          window.removeEventListener('resize', updateDimensions);
    
      }, [])
    
    const updateDimensions = () => {
        const screenWidth = window.innerWidth
        setScreenWidth(screenWidth)
        const screenHeight = window.innerHeight
        setScreenHeight(screenHeight)
    }
    
    return (
        <div className="App" style={{padding:'60px 16px', height: screenHeight, width: screenWidth}}>
            {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"></link> */}

            <div className="w3-container" id="features">

            <div className='loading' style={{marginTop: screenHeight/3}}>
            {/* <img src={icon} height={screenHeight/5}/> */}
            {/* <ImagePicker image={icon} height={screenHeight/5} alt={'page loading'}/> */}
            <picture>
                <source srcSet={icon.webp} type="image/webp" />
                <source srcSet={icon.jrx} type="image/jxr" />
                <source srcSet={icon.jp2} type="image/jp2" />
                <source srcSet={icon.jpg} type="image/jpeg" />
                <img src={icon.jpg} height={screenHeight/5} alt={'page loading'} fetchpriority={'high'}/>
            </picture>
            </div>

            </div>
        </div>
    );
}

export default Loading