
import * as photos from './photos.js'
import * as graphics from './graphics.js'
import * as screenshots from './screenshots.js'
import * as animations from './animations.js'

import { imageFormats } from './assetFormats.js'

const logo = imageFormats(`logo`,`logo`);
const icon = imageFormats(`icon`,`icon no background`);

export { animations, graphics, icon, logo, photos, screenshots,  }