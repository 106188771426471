import React, { useState , useEffect} from "react";
import '../App.css'

function ErrorPage() {

    const [screenWidth, setScreenWidth] = useState(0)
    const [screenHeight, setScreenHeight] = useState(0)

    useEffect(() => { 

        updateDimensions();
    
        window.addEventListener('resize', updateDimensions);
        return () => 
          window.removeEventListener('resize', updateDimensions);
    
      }, [])
    
    const updateDimensions = () => {
        const screenWidth = window.innerWidth
        setScreenWidth(screenWidth)
        const screenHeight = window.innerHeight
        setScreenHeight(screenHeight)
    }
    
    return (
        <div className="App" style={{padding:'60px 16px', height: screenHeight, width: screenWidth}}>
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"></link>

            <div className="w3-container" style={{marginTop: screenHeight/3.5, padding: '5%'}}>

            <h1 style={{fontSize: screenWidth/10}}>Error</h1>
            <h1 style={{fontSize: screenWidth/20}}>Something went wrong. Please reload or try again later.</h1>

            </div>

        </div>
    );
}

export default ErrorPage