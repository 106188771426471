import { Route, Routes } from "react-router-dom";
import React, { lazy, Suspense, useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import Loading from './pages/loading';
// import Public from "./routes/public";
// import Private from "./routes/private";
import ErrorPage from './pages/error';

const Public = lazy(() => import("./routes/public"));
const Private = lazy(() => import("./routes/private"));

Sentry.init({
  dsn: "https://0f583d562fbd4779b16d861407a7f64b@o4503972020027392.ingest.sentry.io/4504120234737664",
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
    ),
})],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function App() {
  return (
    <Suspense fallback={<div><Loading/></div>}>
    <SentryRoutes>
        <Route path='/*' element={<><Public/></>} errorElement={<><ErrorPage/></>}/>
        <Route path='/private/*' element={<><Private/></>} errorElement={<><ErrorPage/></>}/>
    </SentryRoutes>
    </Suspense>
  );
}

export default App;
