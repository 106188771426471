import { imageFormats } from './assetFormats'

const Kate = {
    grad: imageFormats(`photos/kate`, `graduation`),
    grad2: imageFormats(`photos/kate`, `graduation2`),
    hike: imageFormats(`photos/kate`, `hike`),
    selfie: imageFormats(`photos/kate`, `selfie`),
    snow: imageFormats(`photos/kate`, `snow`)
}

const Brian = {
    article: imageFormats(`photos/brian`, `brian`)
}

export { Kate, Brian } 